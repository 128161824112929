import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: { name: "check.authentication" },
    },
    {
      path: "/authenticate",
      name: "check.authentication",
      component: () => import("@/view/pages/Authentication"),
    },
    {
      path: "/admin",
      name: "admin",
      redirect: { name: "admin.dashboard" },
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "dashboard",
          name: "admin.dashboard",
          component: () => import("@/view/pages/Dashboard"),
          meta: {
            requiresAuth: true,
            menuActive: "dashboard",
            title: "Dashboard",
          },
        },
        {
          path: "follow-up",
          name: "admin.follow-up",
          component: () => import("@/view/pages/visit/Follow-Up.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "follow-up",
            title: "Follow-ups",
          },
        },
        {
          path: "follow-up/detail/:id",
          name: "admin.follow-up.detail",
          component: () => import("@/view/pages/visit/Visit-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "follow-up",
            title: "Follow Up Detail",
          },
        },
        {
          path: "incident-report",
          name: "admin.incident-report",
          component: () => import("@/view/pages/incident/Incident-report.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "incident-report",
            title: "Incident Report",
          },
        },
        {
          path: "incident/create",
          name: "admin.incident.create",
          component: () => import("@/view/pages/incident/Create-Report"),
          meta: {
            requiresAuth: true,
            menuActive: "incident-report",
            title: "Create | Incident",
          },
        },
        {
          path: "incident/detail/:id",
          name: "admin.incident.detail",
          component: () => import("@/view/pages/incident/Incident-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "incident-report",
            title: "Incident Detail",
          },
        },
        {
          path: "incident/update/:id",
          name: "admin.incident.update",
          component: () => import("@/view/pages/incident/Create-Report"),
          meta: {
            requiresAuth: true,
            menuActive: "incident-report",
            title: "Update | Incident",
          },
        },
        {
          path: "equipment",
          name: "admin.equipment",
          component: () => import("@/view/pages/equipment/Equipment.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "equipment",
            title: "Tool",
          },
        },
        {
          path: "equipment/create",
          name: "admin.equipment.create",
          component: () =>
            import("@/view/pages/new-equipment/Create-New-Equipment.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "equipment",
            title: "Create | equipment",
          },
        },
        {
          path: "equipment/update/:id",
          name: "admin.equipment.update",
          component: () =>
            import("@/view/pages/new-equipment/Create-New-Equipment.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "equipment",
            title: "Update | equipment",
          },
        },
        {
          path: "equipment/detail/:id",
          name: "admin.equipment.detail",
          component: () =>
            import("@/view/pages/equipment/Detail-Equipment.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "equipment",
            title: "Detail | Equipment",
          },
        },

        //Asset Equipment
        {
          path: "asset-equipment",
          name: "admin.asset-equipment",
          component: () =>
            import("@/view/pages/equipment/Equipment-Company.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "asset-equipment",
            title: "Asset Equipment",
          },
        },
        {
          path: "asset-equipment/create",
          name: "admin.asset-equipment.create",
          component: () =>
            import("@/view/pages/new-equipment/Create-Company-Equipment.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "asset-equipment",
            title: "Create | asset equipment",
          },
        },
        {
          path: "asset-equipment/update/:id",
          name: "admin.asset-equipment.update",
          component: () =>
            import("@/view/pages/new-equipment/Create-Company-Equipment.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "asset-equipment",
            title: "Update | asset equipment",
          },
        },
        {
          path: "asset-equipment/detail/:id",
          name: "admin.asset-equipment.detail",
          component: () =>
            import("@/view/pages/equipment/Detail-Company-Equipment.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "asset-equipment",
            title: "Detail | asset equipment",
          },
        },
        //Asset Equipment

        //Sale Equipment
        {
          path: "sale-equipment",
          name: "admin.sale-equipment",
          component: () => import("@/view/pages/equipment/Equipment.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "sale-equipment",
            title: "Sale Equipment",
          },
        },
        {
          path: "sale-equipment/create",
          name: "admin.sale-equipment.create",
          component: () =>
            import("@/view/pages/new-equipment/Create-New-Equipment.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "sale-equipment",
            title: "Create | sale equipment",
          },
        },
        {
          path: "sale-equipment/update/:id",
          name: "admin.equipment.update",
          component: () =>
            import("@/view/pages/new-equipment/Create-New-Equipment.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "sale-equipment",
            title: "Update | sale equipment",
          },
        },
        {
          path: "sale-equipment/detail/:id",
          name: "admin.sale-equipment.detail",
          component: () =>
            import("@/view/pages/equipment/Detail-Equipment.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "sale-equipment",
            title: "Detail | sale equipment",
          },
        },
        //Sale Equipment

        {
          path: "client-equipment",
          name: "admin.client-equipment",
          component: () =>
            import("@/view/pages/equipment-client/Equipment.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "client-equipment",
            title: "Client Equipment",
          },
        },
        {
          path: "client-equipment/create",
          name: "admin.client-equipment.create",
          component: () =>
            import(
              "@/view/pages/new-equipment-client/Create-Client-Equipment.vue"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "client-equipment",
            title: "Create | Client Equipment",
          },
        },
        {
          path: "client-equipment/update/:id",
          name: "admin.client-equipment.update",
          component: () =>
            import(
              "@/view/pages/new-equipment-client/Create-Client-Equipment.vue"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "client-equipment",
            title: "Update | Client Equipment",
          },
        },
        {
          path: "client-equipment/detail/:id",
          name: "admin.client-equipment.detail",
          component: () =>
            import("@/view/pages/equipment-client/Detail-Equipment.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "client-equipment",
            title: "Detail | Client Equipment",
          },
        },
        {
          path: "enquiry",
          name: "admin.enquiry",
          component: () => import("@/view/pages/enquiry/Enquiry.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "enquiry",
            title: "Enquiry",
          },
        },
        {
          path: "enquiry/create",
          name: "admin.enquiry.create",
          component: () => import("@/view/pages/enquiry/Create-Enquiry"),
          meta: {
            requiresAuth: true,
            menuActive: "enquiry",
            title: "Create | Enquiry",
          },
        },
        {
          path: "enquiry/update/:id",
          name: "admin.enquiry.update",
          component: () => import("@/view/pages/enquiry/Create-Enquiry"),
          meta: {
            requiresAuth: true,
            menuActive: "enquiry",
            title: "Update | Enquiry",
          },
        },
        {
          path: "enquiry/detail/:id",
          name: "admin.enquiry.detail",
          component: () => import("@/view/pages/enquiry/Detail-Enquiry"),
          meta: {
            requiresAuth: true,
            menuActive: "enquiry",
            title: "Detail | Enquiry",
          },
        },
        {
          path: "profile",
          name: "admin.profile",
          redirect: { name: "admin.profile.basic" },
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "basic",
              name: "admin.profile.basic",
              component: () => import("@/view/pages/profile/AdminBasic.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "profile",
                title: "Profile",
              },
            },
            {
              path: "my-account",
              name: "admin.profile.my-account",
              component: () => import("@/view/pages/profile/MyAccount.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "account",
                title: "Account",
              },
            },
            {
              path: "setting",
              name: "admin.profile.setting",
              component: () => import("@/view/pages/profile/Setting.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "setting",
                title: "Setting",
              },
            },
            {
              path: "timeSheet",
              name: "admin.profile.timeSheet",
              component: () => import("@/view/pages/profile/TimeSheet.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "setting",
                title: "Setting",
              },
            },
            {
              path: "visit-timesheet",
              name: "admin.profile.visit-timesheet",
              component: () =>
                import("@/view/pages/profile/Visit-TImeSheet.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "setting",
                title: "Setting",
              },
            },
            {
              path: "activity",
              name: "admin.profile.activity",
              component: () => import("@/view/pages/profile/Activity.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "activity",
                title: "Activity",
              },
            },
            {
              path: "reminders",
              name: "admin.profile.reminders",
              component: () => import("@/view/pages/profile/Reminder.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "reminders",
                title: "Reminder",
              },
            },
          ],
        },
        {
          path: "bug-reports",
          name: "admin.bug.report",
          component: () => import("@/view/pages/bug-report/Bug-Report.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            title: "Bug Report",
          },
        },
        {
          path: "bug-reports/create",
          name: "admin.create.bug.report",
          component: () =>
            import("@/view/pages/bug-report/Create-Bug-Report.vue"),
          meta: {
            requiresAuth: true,
            title: "Create Bug Report",
          },
        },
        {
          path: "bug-reports/detail/:id",
          name: "admin.detail.bug.report",
          component: () =>
            import("@/view/pages/bug-report/Detail-Bug-Report.vue"),
          meta: {
            requiresAuth: true,
            title: "Detail Bug Report",
          },
        },
        {
          path: "email-template/update/:id",
          name: "admin.email.template.update",
          component: () =>
            import("@/view/pages/profile/Setting/CreateEmailTemplate.vue"),
          meta: {
            requiresAuth: true,
            title: "Update Email Template",
          },
        },
        {
          path: "role/create",
          name: "admin.role.create",
          component: () =>
            import("@/view/pages/profile/Setting/CreateRole.vue"),
          meta: {
            requiresAuth: true,
            title: "Create Role",
          },
        },
        {
          path: "role/update/:id",
          name: "admin.role.update",
          component: () =>
            import("@/view/pages/profile/Setting/CreateRole.vue"),
          meta: {
            requiresAuth: true,
            title: "Update Role",
          },
        },
        {
          path: "users",
          name: "admin.profile.users",
          component: () => import("@/view/pages/profile/Users.vue"),
          meta: {
            requiresAuth: true,
            title: "User",
          },
        },
        {
          path: "users/permission/:id",
          name: "admin.profile.userpermission",
          component: () => import("@/view/pages/profile/UserPermission.vue"),
          meta: {
            requiresAuth: true,
            title: "Users Permission",
          },
        },
        /*  {
           path: "/admin/review",
           name: "admin.review",
           component: () => import("@/view/components/Send-Review"),
           meta: { requiresAuth: false, title: "Customer Review" },
         }, */
        {
          path: "calendar",
          name: "admin.calendar",
          component: () => import("@/view/pages/calendar/Calendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Calendar",
          },
        },
        {
          path: "calendar-resource",
          name: "admin.calendar-resource",
          component: () => import("@/view/pages/calendar/ResourceCalendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar-resource",
            title: "Calendar-resource",
          },
        },
        {
          path: "calendar/resource",
          name: "admin.calendar.resource",
          component: () => import("@/view/pages/ResourceCalendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Resource Calendar",
          },
        },
        {
          path: "calendar/resource-h",
          name: "admin.calendar.resource-h",
          component: () => import("@/view/pages/ResourceCalendarHorizontalLT1"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Resource Calendar",
          },
        },
        {
          path: "calendar/map",
          name: "admin.calendar.map",
          component: () => import("@/view/pages/Map"),
          meta: {
            requiresAuth: true,
            menuActive: "map",
            title: "Map",
          },
        },
        {
          path: "purchase-order",
          name: "admin.purchase.order",
          component: () => import("@/view/pages/purchase-order/Purchase-Order"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "purchase-order",
            title: "Purchase Order",
          },
        },
        {
          path: "purchase-order/create",
          name: "admin.purchase.order.create",
          component: () =>
            import(
              "@/view/pages/purchase-order/Create-Or-Update/Create-Purchase-Order"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "purchase-order",
            title: "Create | Purchase Order",
          },
        },
        {
          path: "purchase-order/detail/:id",
          name: "admin.purchase.order.detail",
          component: () =>
            import("@/view/pages/purchase-order/Detail/Detail-Purchase-Order"),
          meta: {
            requiresAuth: true,
            menuActive: "purchase-orde",
            title: "Detail | Purchase Order",
          },
        },
        {
          path: "company",
          name: "admin.company",
          component: () => import("@/view/pages/company/Company"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "company",
            title: "Company",
          },
        },
        {
          path: "company/create",
          name: "admin.company.create",
          component: () => import("@/view/pages/company/Create-Company"),
          meta: {
            requiresAuth: true,
            menuActive: "company",
            title: "Create | Company",
          },
        },
        {
          path: "company/detail/:id",
          name: "admin.company.detail",
          component: () => import("@/view/pages/company/Detail-Company"),
          meta: {
            requiresAuth: true,
            menuActive: "company",
            title: "Detail | Company",
          },
        },

        {
          path: "contact",
          name: "admin.contact",
          component: () => import("@/view/pages/contact/Contact"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "contact",
            title: "Contact",
          },
        },
        {
          path: "contact/create",
          name: "admin.contact.create",
          component: () => import("@/view/pages/contact/Create-Contact"),
          meta: {
            requiresAuth: true,
            menuActive: "contact",
            title: "Create | Contact",
          },
        },
        {
          path: "contact/detail/:id",
          name: "admin.contact.detail",
          component: () => import("@/view/pages/contact/Detail-Contact"),
          meta: {
            requiresAuth: true,
            menuActive: "contact",
            title: "Detail | Contact",
          },
        },
        {
          path: "supplier",
          name: "admin.supplier",
          component: () => import("@/view/pages/supplier/Supplier"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "supplier",
            title: "Supplier",
          },
        },
        {
          path: "supplier/create",
          name: "admin.supplier.create",
          component: () => import("@/view/pages/supplier/Create-Supplier"),
          meta: {
            requiresAuth: true,
            menuActive: "supplier",
            title: "Create | Supplier",
          },
        },
        {
          path: "supplier/detail/:id",
          name: "admin.supplier.detail",
          component: () => import("@/view/pages/supplier/Detail-Supplier"),
          meta: {
            requiresAuth: true,
            menuActive: "supplier",
            title: "Detail | Supplier",
          },
        },
        {
          path: "costing",
          name: "admin.costing",
          component: () => import("@/view/pages/costing/Costing"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "costing",
            title: "Costing",
          },
        },
        {
          path: "costing/create",
          name: "admin.costing.create",
          component: () => import("@/view/pages/costing/Create-Costing"),
          meta: {
            requiresAuth: true,
            menuActive: "costing",
            title: "Create | Costing",
          },
        },
        {
          path: "costing/update/:id",
          name: "admin.costing.update",
          component: () => import("@/view/pages/costing/Create-Costing"),
          meta: {
            requiresAuth: true,
            menuActive: "costing",
            title: "Update | Costing",
          },
        },
        {
          path: "costing/detail/:id",
          name: "admin.costing.detail",
          component: () => import("@/view/pages/costing/Detail-Costing"),
          meta: {
            requiresAuth: true,
            menuActive: "costing",
            title: "Detail | Costing",
          },
        },
        {
          path: "quotation",
          name: "admin.quotation",
          component: () => import("@/view/pages/quotation/Quotation"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "quotation",
            title: "Quotation",
          },
        },
        {
          path: "quotation/create",
          name: "admin.quotation.create",
          component: () => import("@/view/pages/quotation/Create-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Create | Quotation",
          },
        },
        {
          path: "quotation/update/:id",
          name: "admin.quotation.update",
          component: () => import("@/view/pages/quotation/Create-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Update | Quotation",
          },
        },
        {
          path: "quotation/detail/:id",
          name: "admin.quotation.detail",
          component: () => import("@/view/pages/quotation/Detail-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Detail | Quotation",
          },
        },
        {
          path: "orders",
          name: "admin.orders",
          component: () => import("@/view/pages/orders/Orders"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "orders",
            title: "Orders",
          },
        },
        {
          path: "orders/detail/:id",
          name: "admin.orders.detail",
          component: () => import("@/view/pages/orders/Detail-Orders"),
          meta: {
            requiresAuth: true,
            menuActive: "orders",
            title: "Detail | Orders",
          },
        },
        {
          path: "contract",
          name: "admin.contract",
          component: () => import("@/view/pages/contract/Contract"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "contract",
            title: "Contract",
          },
        },
        {
          path: "contract/create",
          name: "admin.contract.create",
          component: () => import("@/view/pages/contract/Create-Contract"),
          meta: {
            requiresAuth: true,
            menuActive: "contract",
            title: "Create | Contract",
          },
        },
        {
          path: "contract/update/:id",
          name: "admin.contract.update",
          component: () => import("@/view/pages/contract/Create-Contract"),
          meta: {
            requiresAuth: true,
            menuActive: "contract",
            title: "Update | Contract",
          },
        },
        {
          path: "contract/detail/:id",
          name: "admin.contract.detail",
          component: () => import("@/view/pages/contract/Detail-Contract"),
          meta: {
            requiresAuth: true,
            menuActive: "contract",
            title: "Detail | Contract",
          },
        },
        {
          path: "user",
          name: "admin.user",
          component: () => import("@/view/pages/user/User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "User",
          },
        },

        {
          path: "user/create",
          name: "admin.user.create",
          component: () => import("@/view/pages/user/Create-User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "Create | User",
          },
        },
        {
          path: "user/detail/:id",
          name: "admin.user.detail",
          component: () => import("@/view/pages/user/Detail-User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "Detail | User",
          },
        },
        {
          path: "property",
          name: "admin.property",
          component: () => import("@/view/pages/property/Property"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "property",
            title: "Site Location",
          },
        },
        {
          path: "property/create",
          name: "admin.property.create",
          component: () => import("@/view/pages/property/Create-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Create | Site Location",
          },
        },
        {
          path: "property/detail/:id",
          name: "admin.property.detail",
          component: () => import("@/view/pages/property/Detail-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Detail | Site Location",
          },
        },
        {
          path: "property/update/:id",
          name: "admin.property.update",
          component: () => import("@/view/pages/property/Create-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Update | Site Location",
          },
        },
        {
          path: "invoice",
          name: "admin.invoice",
          component: () => import("@/view/pages/invoice/Invoice"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "invoice",
            title: "Invoice",
          },
        },
        {
          path: "invoice/create",
          name: "admin.invoice.create",
          component: () => import("@/view/pages/invoice/Create-Invoice"),
          meta: {
            requiresAuth: true,
            menuActive: "invoice",
            title: "Create | Invoice",
          },
        },
        {
          path: "invoice/update/:id",
          name: "admin.invoice.update",
          component: () => import("@/view/pages/invoice/Create-Invoice"),
          meta: {
            requiresAuth: true,
            menuActive: "invoice",
            title: "Update | Invoice",
          },
        },
        {
          path: "invoice/detail/:id",
          name: "admin.invoice.detail",
          component: () => import("@/view/pages/invoice/Detail-Invoice"),
          meta: {
            requiresAuth: true,
            menuActive: "invoice",
            title: "Detail | Invoice",
          },
        },
        {
          path: "credit-note",
          name: "admin.credit-note",
          component: () => import("@/view/pages/credit-note/Credit-Note"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "credit-note",
            title: "Credit Note",
          },
        },
        {
          path: "credit-note/create",
          name: "admin.credit-note.create",
          component: () =>
            import("@/view/pages/credit-note/Credit-Note-Create"),
          meta: {
            requiresAuth: true,
            menuActive: "credit-note",
            title: "Create | Credit Note",
          },
        },
        {
          path: "credit-note/update/:id",
          name: "admin.credit-note.update",
          component: () =>
            import("@/view/pages/credit-note/Credit-Note-Create"),
          meta: {
            requiresAuth: true,
            menuActive: "credit-note",
            title: "Update | Credit Note",
          },
        },
        {
          path: "credit-note/detail/:id",
          name: "admin.credit-note.detail",
          component: () =>
            import("@/view/pages/credit-note/Credit-Note-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "credit-note",
            title: "Detail | Credit Note",
          },
        },
        {
          path: "payment",
          name: "admin.payment",
          component: () => import("@/view/pages/payment/Payment"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "payment",
            title: "Payment",
          },
        },
        {
          path: "payment/detail/:id",
          name: "admin.payment.detail",
          component: () => import("@/view/pages/payment/Detail-Payment"),
          meta: {
            requiresAuth: true,
            menuActive: "payment",
            title: "Detail | Payment",
          },
        },
        {
          path: "payment-credit/detail/:id",
          name: "admin.payment-credit.detail",
          component: () => import("@/view/pages/payment/Detail-Payment-Credit"),
          meta: {
            requiresAuth: true,
            menuActive: "payment",
            title: "Detail | Payment",
          },
        },
        {
          path: "product",
          name: "admin.product",
          component: () => import("@/view/pages/product/Product"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "product",
            title: "Product",
          },
        },
        {
          path: "product/create",
          name: "admin.product.create",
          component: () => import("@/view/pages/product/Create-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Create | Product",
          },
        },
        {
          path: "product/update/:id",
          name: "admin.product.update",
          component: () => import("@/view/pages/product/Create-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Update | Product",
          },
        },
        {
          path: "product/detail/:id",
          name: "admin.product.detail",
          component: () => import("@/view/pages/product/Detail-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Detail | Product",
          },
        },

        {
          path: "part",
          name: "admin.part",
          component: () => import("@/view/pages/part/Part"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "parts",
            title: "Part",
          },
        },
        {
          path: "part/create",
          name: "admin.part.create",
          component: () => import("@/view/pages/part/Create-Part"),
          meta: {
            requiresAuth: true,
            menuActive: "parts",
            title: "Create | Part",
          },
        },
        {
          path: "part/update/:id",
          name: "admin.part.update",
          component: () => import("@/view/pages/part/Create-Part"),
          meta: {
            requiresAuth: true,
            menuActive: "parts",
            title: "Update | Part",
          },
        },
        {
          path: "part/detail/:id",
          name: "admin.part.detail",
          component: () => import("@/view/pages/part/Detail-Part"),
          meta: {
            requiresAuth: true,
            menuActive: "parts",
            title: "Detail | Part",
          },
        },

        {
          path: "error-code",
          name: "admin.error-code",
          component: () => import("@/view/pages/error-code/ErrorCode"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "error-code",
            title: "Error Code",
          },
        },
        {
          path: "error-code/create",
          name: "admin.error-code.create",
          component: () => import("@/view/pages/error-code/Create-ErrorCode"),
          meta: {
            requiresAuth: true,
            menuActive: "error-code",
            title: "Create | Error Code",
          },
        },
        {
          path: "error-code/update/:id",
          name: "admin.error-code.update",
          component: () => import("@/view/pages/error-code/Create-ErrorCode"),
          meta: {
            requiresAuth: true,
            menuActive: "error-code",
            title: "Update | Error Code",
          },
        },
        {
          path: "error-code/detail/:id",
          name: "admin.error-code.detail",
          component: () => import("@/view/pages/error-code/Detail-ErrorCode"),
          meta: {
            requiresAuth: true,
            menuActive: "error-code",
            title: "Detail | Error Code",
          },
        },

        // {
        //   path: "product/adjustment/create",
        //   name: "admin.product.adjustment.create",
        //   component: () =>
        //     import("@/view/pages/product/adjustment/Create-Adjustment"),
        //   meta: {
        //     requiresAuth: true,
        //     menuActive: "product",
        //     title: "Create | Product Adjustment",
        //   },
        // },

        {
          path: "adjustment",
          name: "admin.adjustment",
          component: () => import("@/view/pages/product/adjustment/adjustment"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "adjustment",
            title: "adjustment",
          },
        },
        {
          path: "adjustment/create",
          name: "admin.adjustment.create",
          component: () =>
            import("@/view/pages/product/adjustment/Create-Adjustment"),
          meta: {
            requiresAuth: true,
            menuActive: "adjustment",
            title: "Create | Adjustment",
          },
        },
        {
          path: "adjustment/detail/:id",
          name: "admin.adjustment.detail",
          component: () =>
            import("@/view/pages/product/adjustment/Detail-Adjustment"),
          meta: {
            requiresAuth: true,
            menuActive: "adjustment",
            title: "Detail | Adjustment",
          },
        },

        {
          path: "service",
          name: "admin.service",
          component: () => import("@/view/pages/service/Service"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "service",
            title: "Service",
          },
        },
        {
          path: "service/create",
          name: "admin.service.create",
          component: () => import("@/view/pages/service/Create-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Create | Service",
          },
        },
        {
          path: "service/update/:id",
          name: "admin.service.update",
          component: () => import("@/view/pages/service/Create-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Update | Service",
          },
        },
        {
          path: "service/detail/:id",
          name: "admin.service.detail",
          component: () => import("@/view/pages/service/Detail-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Detail | Service",
          },
        },
        {
          path: "job/create",
          name: "admin.job.create",
          component: () => import("@/view/pages/job/Create-Job"),
          meta: {
            requiresAuth: true,
            menuActive: "visit",
            title: "Create | Visit",
          },
        },
        {
          path: "job/update/:id",
          name: "admin.job.update",
          component: () => import("@/view/pages/job/Create-Job"),
          meta: {
            requiresAuth: true,
            menuActive: "visit",
            title: "Update | visit",
          },
        },
        {
          path: "assessment/create",
          name: "admin.assessment.create",
          component: () => import("@/view/pages/assessment/Create-Assessment"),
          meta: {
            requiresAuth: true,
            menuActive: "assessment",
            title: "Create | Assessment",
          },
        },
        {
          path: "assessment/update/:id",
          name: "admin.assessment.update",
          component: () => import("@/view/pages/assessment/Create-Assessment"),
          meta: {
            requiresAuth: true,
            menuActive: "assessment",
            title: "Update | Assessment",
          },
        },
        {
          path: "delivery/create",
          name: "admin.delivery.create",
          component: () => import("@/view/pages/delivery-job/Create-Job"),
          meta: {
            requiresAuth: true,
            menuActive: "delivery",
            title: "Create | Delivery",
          },
        },
        {
          path: "delivery/update/:id",
          name: "admin.delivery.update",
          component: () => import("@/view/pages/delivery-job/Create-Job"),
          meta: {
            requiresAuth: true,
            menuActive: "delivery",
            title: "Update | delivery",
          },
        },
        /* {
          path: "ServiceReport",
          name: "admin.ServiceReport",
          component: () => import("@/view/pages/service-report/ServiceReport"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "ServiceReport",
            title: "ServiceReport",
          },
        }, */
        {
          path: "visit",
          name: "admin.visit",
          component: () => import("@/view/pages/visit/Visit"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "visit",
            title: "Visit",
          },
        },

        {
          path: "visit/detail/:id",
          name: "admin.visit.detail",
          component: () => import("@/view/pages/visit/Visit-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "visit",
            title: "Visit Detail",
          },
        },
        {
          path: "recurring-job",
          name: "admin.recurring-job",
          component: () => import("@/view/pages/recurring-job/Recurring-Job"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "recurring",
            title: "Recurring-Job",
          },
        },
        {
          path: "recurring-job/detail/:id",
          name: "admin.recurring-job.detail",
          component: () =>
            import("@/view/pages/recurring-job/Recurring-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "recurring",
            title: "Recurring Detail",
          },
        },
        {
          path: "assessment",
          name: "admin.assessment",
          component: () => import("@/view/pages/assessment/Assessment"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "assessment",
            title: "Assessment",
          },
        },
        {
          path: "assessment/detail/:id",
          name: "admin.assessment.detail",
          component: () => import("@/view/pages/assessment/Assessment-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "assessment",
            title: "Assessment Detail",
          },
        },
        {
          path: "delivery",
          name: "admin.delivery",
          component: () => import("@/view/pages/delivery/Visit"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "delivery",
            title: "Delivery",
          },
        },
        {
          path: "delivery/detail/:id",
          name: "admin.delivery.detail",
          component: () => import("@/view/pages/delivery/Visit-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "delivery",
            title: "Delivery Detail",
          },
        },
        {
          path: "engineer",
          name: "admin.engineer",
          component: () => import("@/view/pages/engineer/Engineer"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "engineer",
            title: "Staff",
          },
        },
        {
          path: "engineer/create",
          name: "admin.engineer.create",
          component: () => import("@/view/pages/engineer/Create-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Create | Engineer",
          },
        },
        {
          path: "engineer/update/:id",
          name: "admin.engineer.update",
          component: () => import("@/view/pages/engineer/Create-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Update | Engineer",
          },
        },
        {
          path: "engineer/detail/:id",
          name: "admin.engineer.detail",
          component: () => import("@/view/pages/engineer/Detail-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Detail | Engineer",
          },
        },
        {
          path: "teams",
          name: "admin.teams",
          component: () => import("@/view/pages/team/Teams"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "teams",
            title: "Teams",
          },
        },
        {
          path: "team/:teamId/detail-member",
          name: "admin.team.detailmember",
          component: () => import("@/view/pages/team/Detail-Member"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "teams",
            title: "detail-member",
          },
        },
        {
          path: "calendar/quotation-calendar",
          name: "admin.calendar.quotation-calendar",
          component: () => import("@/view/pages/QuotationCalendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Quotation Calendar",
          },
        },
        {
          path: "calendar/all-calendar",
          name: "admin.calendar.all-calendar",
          component: () => import("@/view/pages/AllCalendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "All Calendar",
          },
        },
        {
          path: "calendar/contract-calendar",
          name: "admin.calendar.contract-calendar",
          component: () => import("@/view/pages/ContractCalendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Contract Calendar",
          },
        },
        {
          path: "warranty",
          name: "admin.warranty",
          component: () => import("@/view/pages/warranty/Warranty"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "warranty",
            title: "Warranty",
          },
        },
        {
          path: "warranty/detail/:id",
          name: "admin.warranty.detail",
          component: () => import("@/view/pages/warranty/Warranty-Detail.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "warranty",
            title: "Warranty Detail",
          },
        },

        {
          path: "preventive-maintanance",
          name: "admin.preventive-maintanance",
          component: () =>
            import("@/view/pages/preventive-maintanance/Preventive"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "preventive-maintanance",
            title: "preventive-maintanance",
          },
        },
        {
          path: "preventive/create",
          name: "admin.preventive.create",
          component: () =>
            import(
              "@/view/pages/preventive-maintanance/Create-Preventive-Maintenace"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "preventive-maintanance",
            title: "Create | Preventive",
          },
        },
        {
          path: "preventive/update/:id",
          name: "admin.preventive.update",
          component: () =>
            import(
              "@/view/pages/preventive-maintanance/Create-Preventive-Maintenace.vue"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "preventive-maintanance",
            title: "Update | Preventive",
          },
        },
        {
          path: "preventive-maintanance/detail/:id",
          name: "admin.preventive-maintanance.detail",
          component: () =>
            import("@/view/pages/preventive-maintanance/Preventive-Detail.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "preventive-maintanance",
            title: "preventive-maintanance Detail",
          },
        },

        {
          path: "Leave",
          name: "admin.leave",
          component: () => import("@/view/pages/leave/Leave2"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "leave",
            title: "Leave",
          },
        },
        {
          path: "leave/detail/:id",
          name: "admin.leave.detail",
          component: () => import("@/view/pages/leave/Detail/Detail-Leave"),
          meta: {
            requiresAuth: true,
            menuActive: "leave",
            title: "Leave | Detail",
          },
        },
        {
          path: "Holiday",
          name: "admin.holiday",
          component: () => import("@/view/pages/Holiday/Holiday"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "holiday",
            title: "Holiday",
          },
        },
        /*  {
          path: "report",
          name: "admin.report",
          component: () => import("@/view/pages/report/ReportListing"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "report",
            title: "Report",
          },
        }, */
        {
          path: "service-form",
          name: "admin.service-form",
          component: () => import("@/view/pages/report/Report-services"),
          meta: {
            isListing: true,
            requiresAuth: true,
          },
        },
        {
          path: "report/:module",
          name: "admin.report.detail",
          component: () => import("@/view/pages/report/Report-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "report",
            title: "Report Detail",
          },
        },

        {
          path: "project/costing/create",
          name: "admin.project.costing.create",
          component: () =>
            import("@/view/pages/project/costing/CreateCosting.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Create Costing | Projects",
          },
        },
        {
          path: "project/costing/update/:id",
          name: "admin.project.costing.update",
          component: () =>
            import("@/view/pages/project/costing/CreateCosting.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Update Costing | Projects",
          },
        },
        {
          path: "project/costing/detail/:id",
          name: "admin.project.costing.detail",
          component: () =>
            import("@/view/pages/project/costing/DetailCosting.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Detail Costing | Projects",
          },
        },
        {
          path: "opportunity-approved/milestone/detail/:id",
          name: "admin.opportunity-approved.milestone.detail",
          component: () =>
            import("@/view/pages/opportunity-approved/milestone/Detail-Milestone.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "opportunity-approved",
            title: "Detail Milestone | Opportunity Approved",
          },
        },
        {
          path: "project",
          name: "admin.project",
          component: () => import("@/view/pages/project/Project.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "project",
            title: "Projects",
          },
        },
        {
          path: "project/create",
          name: "admin.project.create",
          component: () => import("@/view/pages/project/Create-Project.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Create Project",
          },
        },
        {
          path: "project/update/:id",
          name: "admin.project.update",
          component: () => import("@/view/pages/project/Create-Project.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Update Project",
          },
        },
        {
          path: "project/detail/:id",
          name: "admin.project.detail",
          component: () => import("@/view/pages/project/Detail-Project.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Detail Project",
          },
        },
        {
          path: "opportunity-approved",
          name: "admin.opportunity-approved",
          component: () => import("@/view/pages/opportunity-approved/Project.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "opportunity-approved",
            title: "Opportunity Approved",
          },
        },
       
        {
          path: "opportunity-approved/detail/:id",
          name: "admin.opportunity-approved.detail",
          component: () => import("@/view/pages/opportunity-approved/Detail-Project.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "opportunity-approved",
            title: "Detail Opportunity Approved",
          },
        },
        {
          path: "opportunity",
          name: "admin.opportunity",
          component: () => import("@/view/pages/opportunity/Opportunity.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "opportunity",
            title: "Opportunity",
          },
        },
        {
          path: "opportunity/create",
          name: "admin.opportunity.create",
          component: () =>
            import("@/view/pages/opportunity/Create-Opportunity.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "opportunity",
            title: "Create Opportunity",
          },
        },
        {
          path: "opportunity/update/:id",
          name: "admin.opportunity.update",
          component: () =>
            import("@/view/pages/opportunity/Create-Opportunity.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "opportunity",
            title: "Update Opportunity",
          },
        },
        {
          path: "opportunity/detail/:id",
          name: "admin.opportunity.detail",
          component: () =>
            import("@/view/pages/opportunity/Detail-Opportunity.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "opportunity",
            title: "Detail Opportunity",
          },
        },
      ],
    },
    {
      path: "/admin/login",
      name: "admin.login",
      component: () => import("@/view/pages/auth/Login"),
      meta: { requiresAuth: false, title: "Admin | Login" },
    },
    {
      path: "/customer/quotation/approve",
      name: "customer.quotation.approve",
      component: () => import("@/view/customer/quotation/Approve"),
      meta: { requiresAuth: false, title: "Approve | Quotation | Customer" },
    },
    {
      path: "/admin/review",
      name: "admin.review",
      component: () => import("@/view/components/Send-Review"),
      meta: { requiresAuth: true, title: "Customer Review" },
    },

    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1"),
      meta: { requiresAuth: false, title: "Page Not Found" },
    },
  ],
});
