import { cloneDeep/* , toNumber  */} from "lodash";
import ApiService from "@/core/services/api.service";
import ObjPath from "object-path";
// action types
export const SET_LOADING = "setLineItemLoading";
export const CLEAR_LINE_ITEM = "clearTransactionLineItem";
export const SET_DB_LINE_ITEM = "setDbLineItem";
export const SET_LINE_ITEM = "setLineItem";
export const SET_EDIT_LINE_ITEM = "setEditLineItem";
export const SET_CALCULATION = "setCalculation";
export const SET_VIEW_CALCULATION = "setViewCalculation";
export const SET_EDIT_CALCULATION = "setEditCalculation";
export const GET_LINE_ITEM = "getLineItem";
export const SET_BRANDS = "setBrands";
export const SET_MODELS = "setModels";
export const SET_PARTS = "setParts";
export const SET_PRODUCTTYPE = "setProductType";
export const SET_VIEW_LINE_ITEM = "setViewLineItem";

export const SET_CURRENCY = "setCurrency";
export const SET_UOM = "setUom";
export default {
    state: {
        db_loading: false,
        db_line_items: [],
        db_selected_line_items: [],
        db_view_line_items: [],
        db_calculations: {},
        db_view_calculations: {
            total_selling : 0,  
            total_cost_value: 0,
            total_margin: 0,
            discount_value: 0,
            discount_type: 2,
            main_discount : 0,
            lump_discount_value : 0,
            lump_discount_type : 1,
            lump_sum_discount : 0,
            discounted_total_selling : 0,
            grand_total : 0,
        },
        db_edit_line_items: [],
        db_edit_calculations: {},
        db_barnds: [],
        db_models: [],
        db_parts: [],
        db_product_types: [],
        db_currency: [],
        db_uoms: [],
      },
      getters: {
        v2Processing(state) {
          return state.db_loading;
        },
        v2Calculation(state) {
          return state.db_calculations;
        },
        v2DBLineItem(state) {
          return state.db_line_items;
        },
        v2ViewLineItem(state) {
          return state.db_view_line_items;
        },
        v2SelectedLineItem(state) {
          return state.db_selected_line_items;
        },
        v2EditLineItem(state) {
          return state.db_edit_line_items;
        },
        v2EditCalculation(state) {
          return state.db_edit_calculations;
        },
        v2ViewCalculation(state) {
          return state.db_view_calculations;
        },
        v2UomList(state) {
            return state.db_uoms;
        },
        v2BrandList(state) {
          return state.db_barnds;
        },
        v2ModelList(state) {
            return state.db_models;
        },
        v2PartList(state) {
        return state.db_parts;
        },
        v2ProductType(state) {
            return state.db_product_types;
        },
        v2CurrencyList(state) {
            return state.db_currency;
        }
      },
      actions: {
        [CLEAR_LINE_ITEM](context) {
          context.commit(SET_LOADING, false);
          context.commit(SET_DB_LINE_ITEM, []);
          context.commit(SET_LINE_ITEM, []);
          context.commit(SET_CALCULATION, {});
          context.commit(SET_UOM, []);
          context.commit(SET_BRANDS, []);
          context.commit(SET_MODELS, []);
          context.commit(SET_PARTS, []);
          context.commit(SET_PRODUCTTYPE, []);
          context.commit(SET_CURRENCY, []);
        },
        [GET_LINE_ITEM](context, payload) {
          return new Promise((resolve, reject) => {
            context.commit(SET_DB_LINE_ITEM, []);
            ApiService.setHeader();
            context.commit(SET_LOADING, true);
            ApiService.query("product/find-line-items", payload)
              .then((response) => {
                context.commit(
                  SET_DB_LINE_ITEM,
                  ObjPath.get(response, "data.data.line_items", [])
                );
                resolve(true);
              })
              .catch((error) => {
                reject(error);
              })
              .finally(() => {
                context.commit(SET_LOADING, false);
              });
          });
        },
        [SET_LINE_ITEM](context, payload) {
          context.commit(SET_LINE_ITEM, payload);
        },
        [SET_EDIT_LINE_ITEM](context, payload) {
          context.commit(SET_EDIT_LINE_ITEM, payload);
        },
        [SET_EDIT_CALCULATION](context, payload) {
          context.commit(SET_EDIT_CALCULATION, payload);
        },
        [SET_VIEW_CALCULATION](context, payload) {
          context.commit(SET_VIEW_CALCULATION, payload);
        },
        [SET_UOM](context, payload) {
          context.commit(SET_UOM, payload);
        },
        [SET_BRANDS](context, payload) {
            context.commit(SET_BRANDS, payload);
        },
        [SET_MODELS](context, payload) {
            context.commit(SET_MODELS, payload);
        },
        [SET_PARTS](context, payload) {
            context.commit(SET_PARTS, payload);
        },
        [SET_PRODUCTTYPE](context, payload) {
            context.commit(SET_PRODUCTTYPE, payload);
        },
        [SET_CURRENCY](context, payload) {
            context.commit(SET_CURRENCY, payload);
        },
        [SET_LOADING](context, payload) {
          context.commit(SET_LOADING, payload);
        },
        [SET_DB_LINE_ITEM](context, payload) {
          context.commit(SET_DB_LINE_ITEM, payload);
        },
        [SET_CALCULATION](context, payload) {
          context.commit(SET_CALCULATION, payload);
        },
        [SET_VIEW_LINE_ITEM](context, payload) {
          context.commit(SET_VIEW_LINE_ITEM, payload);
        },
      },
      mutations: {
        [SET_LOADING](state, payload) {
          state.db_loading = payload;
        },
        [SET_LINE_ITEM](state, payload) {
          state.db_selected_line_items = cloneDeep(payload);
        },
        [SET_EDIT_LINE_ITEM](state, payload) {
          state.db_edit_line_items = cloneDeep(payload);
        },
        [SET_EDIT_CALCULATION](state, payload) {
          state.db_edit_calculations = cloneDeep(payload);
        },
        [SET_VIEW_CALCULATION](state, payload) {
          state.db_view_calculations = cloneDeep(payload);
        },
        [SET_UOM](state, payload) {
          state.db_uoms = cloneDeep(payload);
        },
        [SET_BRANDS](state, payload) {
            state.db_barnds = cloneDeep(payload);
        },
        [SET_MODELS](state, payload) {
            state.db_models = cloneDeep(payload);
        },
        [SET_PARTS](state, payload) {
            state.db_parts = cloneDeep(payload);
        },
        [SET_PRODUCTTYPE](state, payload) {
            state.db_product_types = cloneDeep(payload);
        },
        [SET_CURRENCY](state, payload) {
            state.db_currency = cloneDeep(payload);
        },
        [SET_CALCULATION](state, payload) {
          state.db_calculations = cloneDeep(payload);
        },
        [SET_DB_LINE_ITEM](state, payload) {
          state.db_line_items = cloneDeep(payload);
        },
        [SET_VIEW_LINE_ITEM](state, payload) {
          state.db_view_line_items = cloneDeep(payload);
        },
      },
}